import React, { useMemo, useState, useEffect } from 'react';
import { Box, Button, Container, Flex, Heading } from '@qga/roo-ui/components';
import DateRange from '@/components/DateRange';
import Travellers from '@/components/Travellers';
import PayWithButtonGroup from '@/components/PayWithButtonGroup';
import useBreakpoints, { isScreen } from '@/hooks/useBreakpoints';
import RouteFrom from './components/RouteFrom';
import RouteTo from './components/RouteTo';
import useRouteDataLayer from './hooks/useRouteDataLayer';
import useSearchForm from './hooks/useSearchForm';
import getDateErrorText from '@/utils/getDateErrorText/getDateErrorText';
import useDestination from '@/hooks/useDestination';

const SearchForm = React.memo(({ searchDestinations, isHomeSearch }) => {
  const destination = useDestination();
  const {
    data,
    error,
    valid,
    changeRoute,
    changeDateRange,
    changeTravellers,
    submit,
  } = useSearchForm({ isHomeSearch, destination });

  const [searched, setSearched] = useState(false);
  const [destinationFocused, setDestinationFocused] = useState(false);
  const [calendarFocused, setCalendarFocused] = useState(false);

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');

  useEffect(() => {
    setSearched(false);
  }, [data.origin?.code]);

  const submitForm = (event) => {
    event.preventDefault();
    if (valid) {
      submit();
    } else {
      setSearched(true);
    }
  };

  useRouteDataLayer(
    data.origin && data.origin.name,
    data.destination && data.destination.title,
    error.route,
  );

  const blackoutDates = useMemo(() => {
    const { destination, origin } = data;

    return destination && origin && destination.blackoutDates[origin.code];
  }, [data]);

  return (
    <Box
      data-testid="SEARCH_FORM"
      position={'relative'}
      bg={['white', null, 'transparent']}
      flex={1}
    >
      <Container px={['0', null, null]}>
        <Box
          bg="white"
          borderRadius={[null, null, 'default']}
          py={[null, null, '7']}
          px={[3, null, '7']}
        >
          <Box>
            <Heading.h3
              data-testid="SEARCH_RESULTS_HEADING"
              fontWeight="normal"
              fontSize={'22px'}
              mb={[4, null, 6]}
            >
              Search for your holiday package
            </Heading.h3>
            <Flex flexDirection={['column', null, 'row']}>
              <Flex flex="1" my={[searched && '0', null, 'unset']}>
                <RouteFrom
                  data={data}
                  onDataChange={changeRoute}
                  hasError={
                    (destinationFocused || calendarFocused || searched) &&
                    !data.origin
                  }
                  searchDestinations={searchDestinations}
                />
              </Flex>
              <Box ml={5} mt={3} />
              <Flex
                flex="1"
                my={[searched && '0', null, 'unset']}
                borderRadius="default"
              >
                <RouteTo
                  data={data}
                  onDataChange={changeRoute}
                  onDestinationFocus={setDestinationFocused}
                  hasError={(calendarFocused || searched) && !data.destination}
                  searchDestinations={searchDestinations}
                />
              </Flex>
              <Box ml={5} mt={3} />
              <Box flex="1" my={[searched && '0', null, 'unset']}>
                <DateRange
                  onCalendarFocus={setCalendarFocused}
                  data={{
                    ...data,
                    originCode: data.origin?.code,
                    destinationCode:
                      data.destination?.searchConfig.destinationCode,
                  }}
                  onDataChange={changeDateRange}
                  blackoutDates={blackoutDates}
                  errorText={getDateErrorText(searched, error)}
                  customBorderColor="colors.greys.alto"
                />
              </Box>
              <Box ml={5} mt={3} />
              <Box width={['auto', null, '160px']}>
                <Travellers
                  occupants={data.occupants}
                  onSubmit={changeTravellers}
                  customBorderColor="colors.greys.alto"
                />
              </Box>
            </Flex>
          </Box>
          <Flex
            justifyContent={'space-between'}
            mt={5}
            flexDirection={['column', null, 'row']}
          >
            <Flex flex="3" mt={[0, null, 0]} mb={8}>
              <PayWithButtonGroup
                toolTipDirection={smallScreen ? 'top' : 'right'}
              />
            </Flex>
            <Box flex="1" pl={[0, null, 15]}>
              <Button
                id="submit"
                variant="primary"
                width="100%"
                onClick={submitForm}
              >
                Search
              </Button>
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
});

export default SearchForm;
