import PropTypes from 'prop-types';
import { map, pipe, unnest } from 'ramda';
import { Flex } from '@qga/roo-ui/components';
import React, { useMemo } from 'react';
import Destination from '@/propTypes/Destination';
import SearchSelect from '@/components/SearchSelect';
import SearchFormControl from '@/components/SearchFormControl';
import FormErrorMessage from '@/shared/components/FormErrorMessage';

const RouteTo = ({
  data,
  onDataChange,
  onDestinationFocus,
  hasError,
  searchDestinations,
}) => {
  const { origin } = data;

  const destinations = useMemo(
    () =>
      pipe(
        map((item) => item.destinations),
        unnest,
      )(searchDestinations),
    [searchDestinations],
  );

  const filteredDestinations = useMemo(
    () =>
      origin
        ? searchDestinations
            .map((state) => ({
              ...state,
              destinations: state.destinations.filter((d) =>
                d.searchConfig.originCodes.find((o) => o === origin.code),
              ),
            }))
            .filter((state) => state.destinations.length > 0)
        : searchDestinations,
    [origin, searchDestinations],
  );

  return (
    <Flex flexDirection="column" flex="1">
      <SearchFormControl
        data-testid="ROUTE_TO"
        label="Travelling to"
        htmlFor="to"
        height="48px"
        width="100%"
        customBorderColor="colors.greys.alto"
        hasError={hasError}
        onFocus={() => {
          onDestinationFocus(true);
        }}
        onBlur={() => {
          onDestinationFocus(false);
        }}
      >
        <SearchSelect
          id="to"
          value={data.destination ? data.destination.name : ''}
          onChange={(event) => {
            const destination = destinations.find(
              (d) => d.name === event.target.value,
            );
            onDataChange({ destination });
          }}
          disabled={!origin}
        >
          <option disabled hidden value="">
            Select destination
          </option>
          {filteredDestinations.map((item) => (
            <optgroup key={item.label} label={item.label}>
              {item.destinations.map(({ name, title }) => (
                <option key={name} value={name}>
                  {title}
                </option>
              ))}
            </optgroup>
          ))}
        </SearchSelect>
      </SearchFormControl>

      {hasError && (
        <FormErrorMessage text="Please select destination" noArrow />
      )}
    </Flex>
  );
};

RouteTo.propTypes = {
  data: PropTypes.shape({
    origin: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
    destination: PropTypes.shape(Destination),
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  onDestinationFocus: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default RouteTo;
