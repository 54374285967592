import PropTypes from 'prop-types';
import { map, pipe, uniq, unnest } from 'ramda';
import React, { useMemo } from 'react';
import { Flex } from '@qga/roo-ui/components';
import Destination from '@/propTypes/Destination';
import SearchFormControl from '@/components/SearchFormControl';
import OriginSelect from '@/components/OriginSelect';
import FormErrorMessage from '@/shared/components/FormErrorMessage';

const RouteFrom = ({ data, onDataChange, hasError, searchDestinations }) => {
  const destinations = useMemo(
    () =>
      pipe(
        map((item) => item.destinations),
        unnest,
      )(searchDestinations),
    [searchDestinations],
  );

  const originCodes = useMemo(
    () =>
      pipe(
        map((d) => d.searchConfig.originCodes),
        unnest,
        uniq,
      )(destinations),
    [destinations],
  );

  return (
    <Flex flexDirection="column" flex="1">
      <SearchFormControl
        data-testid="ROUTE_FROM"
        label="Flying from"
        htmlFor="from"
        height="48px"
        width="100%"
        customBorderColor="colors.greys.alto"
        hasError={hasError}
      >
        <OriginSelect
          id="from"
          codes={originCodes}
          value={data.origin}
          onChange={(origin) => onDataChange({ origin })}
          placeholder="Select location"
        />
      </SearchFormControl>
      {hasError && <FormErrorMessage text="Please select location" noArrow />}
    </Flex>
  );
};

RouteFrom.propTypes = {
  data: PropTypes.shape({
    origin: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
    destination: PropTypes.shape(Destination),
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default RouteFrom;
